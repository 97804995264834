<template>
  <main>
    <div class="page-header">
      <div class="container-fluid">
        <breadcrumbs></breadcrumbs>
        <div class="d-lg-flex align-items-end justify-content-between align-items-center">
          <h1 class="page-title mb-3 mb-md-2">Request Land Title Correction</h1>
        </div>
      </div>
    </div>

    <div class="container-fluid">

      <h2 class="mb-4"></h2>
      <div class="row">
        <div class="col-md-8">
          <p class="lead">
            Complete the application to request a correction to a land title resulting from an error made when it was registered. This form is not to be used to submit a Corrective Declaration or Plan Alteration. To submit a Corrective Declaration or Plan Alteration, go to the View Package screen of the package to be corrected.
          </p>
        </div>
      </div>

      <form class="row mt-2">
        <div class="col-sm-8 col-md-6 col-lg-5">
          <a href="#" class="btn btn-link pl-0 mb-2">Clear all fields</a>

          <div class="form-group">
            <label for="fileReference">
              File Reference
              <a href="#" data-toggle="popover" data-placement="top" data-title="Help Text" data-content="This is an example of some help text" v-popover>
                <svg class="icon icon-help"><use xlink:href="/icons/symbol-defs.svg#icon-help"></use></svg>
              </a>
            </label>
            <input class="form-control" type="text" name="fileReference" id="fileReference" autocorrect="off" autocomplete="given-name" autofocus>
          </div>

          <div class="form-group">
            <label for="applicationName">Applicant Name</label>
            <input class="form-control" type="text" name="applicationName" id="applicationName" autocorrect="off">
          </div>

          <div class="form-group">
            <label for="phoneNumber">Phone Number</label>
            <input class="form-control" type="text" name="phoneNumber" id="phoneNumber" autocorrect="off">
          </div>

          <div class="form-group">
            <label for="email">Email (optional)</label>
            <input class="form-control" type="email" name="email" id="email" autocorrect="off">
          </div>

          <div class="form-group">
            <label for="affectedPid1">
              Affected PID 1 (optional)
              <a href="#" data-toggle="popover" data-placement="top" data-title="Help Text" data-content="This is an example of some help text" v-popover>
                <svg class="icon icon-help"><use xlink:href="/icons/symbol-defs.svg#icon-help"></use></svg>
              </a>
            </label>
            <input class="form-control" type="text" name="affectedPid1" id="affectedPid1" autocorrect="off">
          </div>

          <div class="form-group">
            <label for="affectedPid2">Affected PID 1 (optional)</label>
            <input class="form-control" type="text" name="affectedPid2" id="affectedPid2" autocorrect="off">
          </div>

          <div class="form-group">
            <label for="affectedPid3">Affected PID 3 (optional)</label>
            <input class="form-control" type="text" name="affectedPid3" id="affectedPid3" autocorrect="off">
          </div>
        </div>

        <div class="col-md-8">
          <div class="form-group mt-4">
            <label for="supportingInformation">
              Provide a description of the correction request
              <a href="#" data-toggle="popover" data-placement="top" data-content="Help Text" v-popover>
                <svg class="icon icon-help"><use xlink:href="/icons/symbol-defs.svg#icon-help"></use></svg>
              </a>
            </label>

            <textarea class="form-control mb-1" name="supportingInformation" id="supportingInformation" rows="4"></textarea>
          </div>

          <div class="form-group mt-4">
            <label>Upload supporting document (optional)</label>
            <file-upload></file-upload>
          </div>

          <label class="mt-3">Do you want to receive a confirmation of this correction?</label>
          <div class="form-group mt-1">
            <div class="custom-control custom-radio custom-control-inline">
              <input type="radio" id="jointTenants" name="jointTenants" class="custom-control-input" checked>
              <label class="custom-control-label" for="jointTenants">Yes</label>
            </div>
            <div class="custom-control custom-radio custom-control-inline">
              <input type="radio" id="jointTenants2" name="jointTenants" class="custom-control-input">
              <label class="custom-control-label" for="jointTenants2">No</label>
            </div>
          </div>

          <div class="form-group mt-4">
            <button type="submit" class="btn btn-primary btn-lg">Submit</button>
          </div>
        </div>
      </form>

      <!-- Scroll to top -->
      <a href="#top" class="d-block float-right d-print-none scroll-to-top">
        <svg class="icon icon-arrow_upward"><use xlink:href="/icons/symbol-defs.svg#icon-arrow_upward"></use></svg>Back to Top
      </a>
    </div>

    <help-panel title="Manage Certificates"></help-panel>
  </main>

</template>

<script>

import PackageHeader from '../../components/PackageHeader.vue'
import HelpPanel from '../../components/HelpPanel.vue'
import FileUpload from '../../components/FileUpload.vue'

export default {

  components: {
    'package-header': PackageHeader,
    'help-panel': HelpPanel,
    'file-upload': FileUpload
  },

  data () {
    return {

    }
  }
}
</script>

